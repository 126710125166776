import { EventEmitter } from 'events';
import { FileDetailsObject } from '../../../models/FileDetails';
import { FileSession } from '../../../models/FileSession';
import FileManagerAPIService from './FileManagerAPIService';
import { FolderItemClassResponse } from '../../../models/FileItemClasses';
import { fetchClassPropertiesService } from '../../../apis/fileService';
import { AttachedItem } from '../../../models/AttachedItem';
import { ConnectedSourceItemsRequest } from '../../../models/connectedSourceItemsRequest';
import { RemoveAttachedConnectionsRequest } from '../../../models/RemoveAttachedConnectionsRequest';
import { FileAttachmentRequest } from '../../../models/FileAttachmentRequest';
import { ShowToast } from '../Notifications/ShowToast';

class FileManagerOperations extends EventEmitter {

    public treeViewFolders: FileDetailsObject[] = [];
    public gridViewItems: FileDetailsObject[] = [];
    public selectedTreeViewItem: FileDetailsObject | null = null;
    public selectedGridViewItems: FileDetailsObject[] = [];
    public clipboard: FileDetailsObject[] = [];
    public breadcrumbs: FileDetailsObject[] = [];
    public selectedFileItem: FileDetailsObject | null = null;
    public highlightedItem: FileDetailsObject | null = null;
    private fileSession: FileSession;
    public contextMenuTreeViewItem: FileDetailsObject | null = null;
    public dialogVisible: boolean = false;
    public newFolderName: string = "";
    public selectedDialog: string = "";
    public displayVersion: string = "";
    public attachedModel: string = "";
    public attachedItems: AttachedItem[] = [];
    public itemClassResponse: FolderItemClassResponse | null = null;
    public resizeToken: number = 0;
    //const[folderItemClassResponse, setFolderItemClassResponse] = useState<FolderItemClassResponse>();

    public currentPage: number = 1;
    public pageSize: number = 50;
    public totalRecords: number = 0;

    constructor(fileSession: FileSession) {
        super();
        this.fileSession = fileSession;
    }

    setDisplayVersion(displayVersion: string) {
        this.displayVersion = displayVersion;
        this.emit('displayVersionUpdated', this.displayVersion);
    }
    setAttachedModel(attachedModel: string) {
        this.attachedModel = attachedModel;
        this.emit('attachedModelUpdated', this.attachedModel);
    }


    setAttachedItems(newItems: AttachedItem[]) {
        this.attachedItems = newItems;
        this.emit('attachedItemsUpdated', this.attachedItems);
    }

    setSelectedFileItem(item: FileDetailsObject) {
        this.selectedFileItem = item;
        this.emit('fileItemSelected', this.selectedFileItem);
    }

    setHighlightedItem(item: FileDetailsObject) {
        this.highlightedItem = item;
        this.emit('highlightedItemChanged', this.highlightedItem);
    }
    // Set and track the selected TreeView item
    setSelectedTreeViewItem(item: FileDetailsObject) {
        this.setSelectedFileItem(item);
        this.selectedTreeViewItem = item;
        this.emit('treeViewSelectionChanged', this.selectedTreeViewItem);
        this.fetchGridViewItems();
    }

    setSelectDialog(dialog: string) {
        this.selectedDialog = dialog;
        this.emit('selectedDialogChanged', this.selectedDialog);
    }

    setDialogOpen() {
        this.dialogVisible = true;
        this.emit('dialogOpen', this.dialogVisible);
    }

    setDialogClose() {
        this.dialogVisible = false;
        this.emit('dialogClose', this.dialogVisible);
    }

    setTotalRecords(total: number) {
        this.totalRecords = total;
        this.emit('pagingUpdated', { totalRecords: this.totalRecords });
    }

    setResizeToken() {
        this.resizeToken = this.resizeToken + 1;
        this.emit('resizeTokenChanged', this.resizeToken);
    }

    // Set and track the selected GridView items
    setSelectedGridViewItems(items: FileDetailsObject[]) {
        this.selectedGridViewItems = items;

        // Get the last item or fallback to the selectedTreeViewItem
        let selectedFileItem = this.selectedGridViewItems?.length
            ? this.selectedGridViewItems[this.selectedGridViewItems.length - 1] // Last item
            : this.selectedTreeViewItem; // Default fallback

        if (selectedFileItem) {
            this.setSelectedFileItem(selectedFileItem);
        }

        this.emit('gridViewSelectionChanged', this.selectedGridViewItems);
    }


    setContextMenuTreeViewItem(item: FileDetailsObject) {
        this.contextMenuTreeViewItem = item;
        this.emit('treeViewContextMenuChanged', this.contextMenuTreeViewItem);
    }


    setNewFolderName(name: string) {
        this.newFolderName = name;
        this.emit('newFolderNameChanged', this.newFolderName);
    }

    handleNewFolderNameChange = (e: any) => {
        this.newFolderName = e.target.value;
        this.emit('newFolderNameChanged', this.newFolderName);
    };


    showToastNotification(message: string, header: string, type: 'Success' | 'Error' | 'Warning' | 'Information') {
        ShowToast({ header, message, type });
    }

    // Get the current folder ID from the selected TreeView item
    private getCurrentFolderId(): string | null {
        if (!this.selectedFileItem?.isFile) {
            return this.selectedFileItem?.id || null;
        }
        return "0";
    }

    private getCurrentProviderId(): string | null {
        if (!this.selectedFileItem?.isFile) {
            return this.selectedFileItem?.fileProviderId || null;
        }
        return "0";
    }

    async updateCurrentItem(fileId: string) {
        try {
            const response = await FileManagerAPIService.fetchUpdatedDetails(fileId, this.fileSession);

            var updatedItem = response.Data as FileDetailsObject;
            debugger;
            if (updatedItem) {
                // Update tree view folders
                let savedHighlight = this.highlightedItem;
                this.treeViewFolders = this.treeViewFolders.map(item =>
                    item.id === fileId ? { ...item, ...updatedItem } : item
                );

                // Update grid view items
                this.gridViewItems = this.gridViewItems.map(item =>
                    item.id === fileId ? { ...item, ...updatedItem } : item
                );
                
                if (savedHighlight) {
                    this.emit('gridViewUpdated', updatedItem);
                    this.setHighlightedItem(savedHighlight);
                }
            }
        } catch (e) {
            console.error("Error updating item:", e);
        }
    }


    // Fetch folders for TreeView
    async fetchTreeViewFolders(fileId: string) {
        try {
            // Fetch folders from the API
            const newFolders = await FileManagerAPIService.fetchTreeViewFolders(fileId, this.fileSession);

            if (!newFolders || newFolders.length === 0) {
                console.warn('No new folders received from the API.');
                return; // Exit early if there's nothing to process
            }

            // Update the existing tree view folders directly
            this.updateTreeViewInPlace(this.treeViewFolders, newFolders);

            // Expand the path for the updated node
            await this.expandPath(fileId, this.treeViewFolders);

            // Emit an event to notify that the tree view has been updated
            this.emit('treeViewUpdated', this.treeViewFolders);
        } catch (error) {
            console.error('Error fetching tree view folders:', error);
        }
    }

    updateTreeViewInPlace(
        currentTree: FileDetailsObject[],
        newFolders: FileDetailsObject[]
    ): void {
        if (!newFolders || newFolders.length === 0) {
            console.warn('No new folders to update.');
            return;
        }

        // Map existing tree items by their ID for quick lookups
        const treeMap = new Map(currentTree.map(item => [item.id, item]));

        newFolders.forEach(folder => {
            const existingFolder = treeMap.get(folder.id);
            if (existingFolder) {
                // Mutate the existing folder object directly
                Object.assign(existingFolder, folder);
            } else {
                // Add new folder directly to the array
                currentTree.push(folder);
                treeMap.set(folder.id, folder);
            }
        });
    }

    expandPath(fileId: string, tree: FileDetailsObject[]): void {
        // Create a map for quick lookup
        const treeMap = new Map<string, FileDetailsObject>(tree.map(item => [item.id, item]));

        let currentId = fileId;

        while (currentId) {
            const currentFolder = treeMap.get(currentId);
            if (currentFolder) {
                currentFolder.isExpanded = true; // Expand the current folder
                currentId = currentFolder.parentId; // Move to the parent
            } else {
                break; // Stop if the folder is not found
            }
        }
        //this.emit('treeViewUpdated', this.treeViewFolders);
    }


    async fetchSelectedFileContent() {
        try {
            const result = await fetchClassPropertiesService(this.fileSession);
            if (result.data) {
                if (result.data.Success) {
                    const fileClasses = result.data.Data;
                    let itemClassResponse: FolderItemClassResponse = {
                        classes: fileClasses.classes.map((classItem: any) => ({
                            className: classItem.class_name,
                            classId: classItem.class_id,
                            classProperties: classItem.class_properties.map((property: any) => ({
                                propertyName: property.property_name,
                                type: property.type,
                                relationalTable: property.relational_table,
                                isObligatory: property.is_obligatory,
                                isEditable: property.is_editable,
                                query: property.query,
                                valueListData: property.value_list_data,
                            })),
                        })),
                    };

                    this.itemClassResponse = itemClassResponse;
                    this.emit('itemClassResponseUpdated', this.itemClassResponse);
                } else {
                    //showToast("An error occurred. Please refresh and try again", "Document Classes", "e-toast-danger");
                }
            }
        } catch (e) {
        }
    }

    async refreshAllFiles() {
        const providerId = this.getCurrentProviderId();

        try {
            await FileManagerAPIService.refreshFiles(providerId ?? "0", this.fileSession);

        } catch (error) {
            console.error('Error fetching grid view items:', error);
        }
    }

    async refreshFiles(fileItem: FileDetailsObject) {

        if (fileItem.isFile) {
            await this.updateGridViewItems(fileItem.parentId);
            this.emit('treeviewNodesUpdated');
        }
        else {
            await this.fetchTreeViewFolders(fileItem.parentId).then(async () => {
                await this.updateGridViewItems(fileItem.id)

            }).then(() => {
                this.emit('treeviewNodesUpdated')
            }
            );
        }
    }

    async refreshImmediateChildren(fileItem: FileDetailsObject) {

        if (fileItem.isFile) {
            await this.updateGridViewItems(fileItem.parentId);
            this.emit('treeviewNodesUpdated');
        }
        else {
            await this.fetchTreeViewFolders(fileItem.parentId).then(async () => {
                await this.updateGridViewItems(fileItem.parentId)

            }).then(() => {
                this.emit('treeviewNodesUpdated')
            }
            );
        }
    }

    async refreshMovedChildren(fileItemFrom: FileDetailsObject, fileItemTo: FileDetailsObject) {
        const refreshNode = async (fileItem: FileDetailsObject) => {
            const parentId = fileItem.isFile ? fileItem.parentId : fileItem.id;
            await this.fetchTreeViewFolders(parentId);
            await this.updateGridViewItems(parentId);
            this.emit('treeviewNodesUpdated');
        };

        // Refresh the source and destination nodes
        await refreshNode(fileItemFrom);
        await refreshNode(fileItemTo);
    }


    async refreshFilesAfterRename(fileItem: FileDetailsObject) {

        if (fileItem.isFile) {
            await this.updateGridViewItems(fileItem.parentId);
            this.emit('treeviewNodesUpdated');
        }
        else {
            //let fileId = await this.findTreeNodeById(fileItem.parentId);
            //await this.fetchTreeViewFolders(fileId).then(async () => {
            //    await this.updateGridViewItems(fileItem.id)

            //}).then(() => {
            //    this.emit('treeviewNodesUpdated')
            //}
            //);
        }
    }

    async getAttachedItems() {
        const fileAttachmentRequests = new ConnectedSourceItemsRequest(this.attachedModel);

        // Clear existing attached items
        this.setAttachedItems([]);

        // Fetch file classes
        const fileClasses = await FileManagerAPIService.getAttachedItems(fileAttachmentRequests, this.fileSession);

        // Map to AttachedItem instances and update attachedItems
        const newItems = fileClasses.map(a => new AttachedItem(a.Target, a.Name, a.Name));
        this.setAttachedItems(newItems);
    }



    // Fetch items for GridView in the selected folder
    async fetchGridViewItems() {
        const folderId = this.getCurrentFolderId();
        if (!folderId) {
            console.error("No folder selected for fetching GridView items.");
            return;
        }
        try {
            const items = await FileManagerAPIService.fetchGridViewItems(folderId, this.fileSession);
            this.gridViewItems = items;
            this.expandPath(folderId, this.treeViewFolders);
            if (this.selectedFileItem) {
                this.setHighlightedItem(this.selectedFileItem);
                this.updateBreadcrumbs();
            }
            this.emit('gridViewUpdated', this.gridViewItems);
            this.emit('pagingUpdated', {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                totalRecords: this.totalRecords,
            });
        } catch (error) {
            console.error('Error fetching grid view items:', error);
        }
    }

    async findTreeNodeById(id: string): Promise<FileDetailsObject | undefined> {
        const traverseNodes = async (nodes: FileDetailsObject[]): Promise<FileDetailsObject | undefined> => {
            for (const node of nodes) {
                if (node.id === id) {
                    return node;
                }
                if (node.data && node.data.length > 0) {
                    const childNode = await traverseNodes(node.data);
                    if (childNode) {
                        return childNode;
                    }
                }
            }
            return undefined;
        };

        // Start traversal from the root treeViewFolders
        return traverseNodes(this.treeViewFolders);
    }

    async getFolderGenerations(folderId: string): Promise<FileDetailsObject[]> {
        const result: FileDetailsObject[] = [];
        const folderDepth = 4; // Maximum depth to traverse
        // Reference to the outer context's `this`
        const treeViewFolders = this.treeViewFolders;

        // Recursive function to fetch children
        async function fetchChildrenRecursively(
            parentId: string,
            currentDepth: number
        ): Promise<void> {
            if (currentDepth > folderDepth) {
                return; // Stop at the 4th generation
            }

            // Find all children of the current parentId
            const children = treeViewFolders.filter(folder => folder.parentId === parentId);

            // Add the children to the result array
            result.push(...children);

            // Recursively find their children
            for (const child of children) {
                await fetchChildrenRecursively(child.id, currentDepth + 1);
            }
        }

        // Start fetching children from the given folderId
        await fetchChildrenRecursively(folderId, 1);

        return result;
    }

    async getFolderGenerationsUp(
        fileId: string,
        levels: number
    ): Promise<FileDetailsObject | undefined> {
        // Helper function to find a node by its ID
        const findNodeById = (id: string): FileDetailsObject | undefined => {
            return this.treeViewFolders.find(folder => folder.id === id);
        };

        let currentNode = findNodeById(fileId);

        if (!currentNode) {
            console.warn(`Node with id ${fileId} not found.`);
            return undefined;
        }

        if (levels === 0) {
            // If level is 0, return the current node itself
            return currentNode;
        }

        try {
            if (levels > 0) {
                // Traverse down (levels > 0)
                for (let i = 0; i < levels; i++) {
                    const childNode = this.treeViewFolders.find(
                        folder => folder.parentId === currentNode?.id
                    );

                    if (!childNode) {
                        console.warn(`No child found at level ${i + 1} from node ${currentNode.id}.`);
                        return currentNode; // Return the closest match (friendly father)
                    }

                    currentNode = childNode;
                }
            } else {
                // Traverse up (levels < 0)
                for (let i = 0; i < Math.abs(levels); i++) {
                    const parentNode = this.treeViewFolders.find(
                        folder => folder.id === currentNode?.parentId
                    );

                    if (!parentNode) {
                        console.warn(`No parent found at level ${i + 1} from node ${currentNode.id}.`);
                        return currentNode; // Return the closest match (friendly father)
                    }

                    currentNode = parentNode;
                }
            }
        } catch (error) {
            console.error(`Error finding relative node for fileId ${fileId} and levels ${levels}:`, error);
            return currentNode; // Fallback to the closest match
        }

        return currentNode;
    }

    async updateBreadcrumbs(): Promise<void> {
        const result: FileDetailsObject[] = [];
        const treeViewFolders = this.treeViewFolders;

        // Get the folder ID from the highlighted item
        const folderId = this.highlightedItem?.id;

        if (!folderId) {
            console.warn('No folderId found for the highlighted item.');
            this.breadcrumbs = [];
            return;
        }

        // Recursive function to fetch ancestors
        const fetchAncestorsRecursively = (childId: string): void => {
            const folder = treeViewFolders.find(item => item.id === childId);

            if (!folder) {
                console.warn(`Folder not found for id: ${childId}`);
                return; // Stop if no folder is found
            }

            // Add the current folder to the result
            result.unshift(folder); // Add to the start for breadcrumb ordering

            // Continue upwards until `isRoot=true` or no `parentId`
            if (!folder.isRoot && folder.parentId) {
                fetchAncestorsRecursively(folder.parentId);
            }
        };

        // Start fetching ancestors from the given folderId
        fetchAncestorsRecursively(folderId);

        // Update breadcrumbs
        this.breadcrumbs = result;

        console.log('Updated breadcrumbs:', this.breadcrumbs);
    }


    async updateGridViewItems(folderId: string) {

        if (!folderId) {
            console.error("No folder selected for fetching GridView items.");
            return;
        }
        try {
            const items = await FileManagerAPIService.fetchGridViewItems(folderId, this.fileSession);
            this.gridViewItems = items;
            this.emit('gridViewUpdated', this.gridViewItems);
            this.emit('pagingUpdated', {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                totalRecords: this.totalRecords,
            });
        } catch (error) {
            console.error('Error fetching grid view items:', error);
        }
    }

    async fetchGridViewItemsPaged(page: number = this.currentPage, size: number = this.pageSize) {
        try {
            this.currentPage = page;
            this.pageSize = size;
            const folderId = this.getCurrentFolderId();
            if (!folderId) {
                console.error("No folder selected for fetching GridView items.");
                return;
            }

            const items = await FileManagerAPIService.fetchGridViewItems(folderId, this.fileSession);
            this.gridViewItems = items;
            this.totalRecords = items.length;

            this.emit('gridViewUpdated', this.gridViewItems);
            this.emit('pagingUpdated', {
                currentPage: this.currentPage,
                pageSize: this.pageSize,
                totalRecords: this.totalRecords,
            });
        } catch (error) {
            console.error('Error fetching grid data:', error);
        }
    }

    // Create a new folder in the selected TreeView folder
    async createFolder(folderName: string) {
        const parentId = this.getCurrentFolderId();
        if (!parentId) {
            console.error("No folder selected for creating a new folder.");
            return;
        }
        try {
            const newFolder = await FileManagerAPIService.createFolder(parentId, folderName, this.fileSession);
            this.emit('folderCreated', newFolder); // Broadcast creation
            if (this.selectedFileItem) {
                await this.refreshImmediateChildren(this.selectedFileItem);
            }
        } catch (error) {
            console.error('Error creating folder:', error);
        }
    }

    // Rename an item in the selected GridView
    async renameItem(newName: string) {
        if (!this.selectedFileItem) {
            this.showToastNotification("Select a single item to rename.", "File Rename", "Warning");
            return;
        }
        const item = this.selectedFileItem;
        try {
            if (item) {
                item.newName = newName;
                const renamedItem = await FileManagerAPIService.rename(item, this.fileSession);

                this.showToastNotification(`${item.name} was successfully renamed to ${newName}`, "File Rename", "Success");

                this.refreshImmediateChildren(item);
                this.emit('itemRenamed', renamedItem);
            }
        } catch (error) {
            console.error('Error renaming item:', error);
            this.showToastNotification(`An error occurred while renaming ${item.name} to ${newName}`, "File Rename", "Success");
        }
    }

    // Delete selected items in GridView
    async deleteSelectedItems() {
        if (this.selectedGridViewItems.length === 0) {
            console.error("No items selected to delete.");
            return;
        }
        try {

            const fileIds = this.selectedGridViewItems.map(item => item.id);
            await FileManagerAPIService.delete(fileIds, this.fileSession);


            if (this.selectedFileItem) {
                await this.refreshImmediateChildren(this.selectedFileItem);
            }

            this.emit('itemsDeleted', this.selectedGridViewItems); // Broadcast deletion
            this.selectedGridViewItems = []; // Clear selection

        } catch (error) {
            console.error('Error deleting items:', error);
        }
    }

    // Copy or cut items and prepare for paste
    copyItems() {
        this.clipboard = this.selectedGridViewItems.map(item => ({ ...item, action: 'copy' }));
        if (this.clipboard.length === 0 && this.selectedFileItem) {
            this.clipboard = [{ ...this.selectedFileItem, action: 'copy' }];
        }
        this.emit('clipboardUpdated', this.clipboard);
    }

    cutItems() {
        this.clipboard = this.selectedGridViewItems.map(item => ({ ...item, action: 'cut' }));
        if (this.clipboard.length === 0 && this.selectedFileItem) {
            this.clipboard = [{ ...this.selectedFileItem, action: 'cut' }];
        }
        this.emit('clipboardUpdated', this.clipboard);
    }


    // Paste items into the currently selected folder
    async pasteItems() {
        const targetFolderId = this.getCurrentFolderId();
        if (!targetFolderId) {
            console.error("No target folder selected for pasting items.");
            return;
        }
        const action = this.clipboard[0]?.action as 'copy' | 'cut';
        try {
            const pastedItems = await FileManagerAPIService.paste(this.clipboard, targetFolderId, action, this.fileSession);
            this.emit('itemsPasted', pastedItems);

            if (action === 'cut') {
                var fileItemFrom = this.clipboard[0];
                if (fileItemFrom && this.selectedFileItem) {
                    await this.refreshMovedChildren(this.selectedFileItem, fileItemFrom);
                }
                this.clearClipboard();
                this.showToastNotification("Items moved successfully", "File Move", "Success");
            }
            else {
                if (this.selectedFileItem) {
                    await this.refreshFiles(this.selectedFileItem);
                }
                this.clearClipboard();
                this.showToastNotification("Items copied successfully", "File Copy", "Success");
            }

        } catch (error) {
            console.error('Error pasting items:', error);
        }
    }

    // Upload files to the currently selected folder in TreeView
    async uploadItems(files: File[]) {
        const targetFolderId = this.getCurrentFolderId();
        if (!targetFolderId) {
            console.error("No target folder selected for uploading files.");
            return;
        }
        try {
            const uploadedItems = await FileManagerAPIService.uploadFiles(targetFolderId, files, this.fileSession);
            this.emit('itemsUploaded', uploadedItems);
            if (this.selectedFileItem) {
                await this.refreshFiles(this.selectedFileItem);

            }
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    }

    // Download selected items in GridView
    async downloadSelectedItems() {
        if (this.selectedGridViewItems.length === 0) {
            try {
                const downloadItems: FileDetailsObject[] = this.selectedFileItem ? [this.selectedFileItem] : [];
                await FileManagerAPIService.downloadFiles(downloadItems, this.fileSession);
                this.emit('downloadCompleted', this.selectedGridViewItems); // Broadcast download completion
            } catch (error) {
                console.error('Error downloading files:', error);
            }
        }
        else {
            try {
                await FileManagerAPIService.downloadFiles(this.selectedGridViewItems, this.fileSession);
                this.emit('downloadCompleted', this.selectedGridViewItems); // Broadcast download completion
            } catch (error) {
                console.error('Error downloading files:', error);
            }
        }
    }

    async removeAllAttachement() {
        const selectedIds = this.attachedItems;
        let fileAttachmentRequests: RemoveAttachedConnectionsRequest[] = [];

        selectedIds.forEach(
            (a: any) => {
                const newAttachedItem = new RemoveAttachedConnectionsRequest(a.id, this.attachedModel);
                fileAttachmentRequests.push(newAttachedItem);
            }
        );


        const result = await FileManagerAPIService.removeAttachedItems(fileAttachmentRequests, this.fileSession);
        if (result.data) {
            if (result.data.Success) {
                this.setDialogClose();
            } else {
                //showToast("An error occurred. Please refresh and try again", "Attached Document", "e-toast-danger");
            }
        }
    }

    async removeAttachement(selectedIds: string[]) {
        let fileAttachmentRequests: RemoveAttachedConnectionsRequest[] = [];
        selectedIds.forEach(
            (a: any) => {
                const newAttachedItem = new RemoveAttachedConnectionsRequest(a, this.attachedModel);
                fileAttachmentRequests.push(newAttachedItem);
            }
        );
    }

    async addToFileAttachement() {
        if (this.displayVersion === "select") {

            var allItems = this.selectedGridViewItems;
            let fileAttachmentRequests: FileAttachmentRequest[] = [];


            allItems.forEach(
                (a) => {
                    const newAttachedItem = new FileAttachmentRequest(a?.id, this.attachedModel, this.attachedModel, this.attachedModel);
                    fileAttachmentRequests.push(newAttachedItem);
                }
            );

            const result = await FileManagerAPIService.addFilesToAttachedItems(fileAttachmentRequests, this.fileSession);
            var data = result.data;
            if (data) {
                //showToast(allItems.length + " attachments added to object " + attachedModel + " successfully", "Attachments", "e-toast-success");
            }
            else {
                //showToast(" Failed to add attachments to object " + attachedModel, "Attachments", "e-toast-warning");
            }
        }
    }


    // Clear clipboard after cut-paste action
    clearClipboard() {
        this.clipboard = [];
        this.emit('clipboardCleared');
    }


}

export default FileManagerOperations;