import { createRef, useEffect, useRef, useState } from 'react';
import FileManagerOperations from '../Services/FileManagerOperations';
import { FileDetailsObject } from '../../../models/FileDetails';
import { TreeViewComponent, NodeExpandEventArgs, ContextMenuComponent } from '@syncfusion/ej2-react-navigations';

interface Props {
    FileManagerOperations: FileManagerOperations;
}

export default function FileManagerTreeView({ FileManagerOperations }: Props) {
    const treeViewRef = useRef<TreeViewComponent>(null);
    const [treeViewData, setTreeViewData] = useState<FileDetailsObject[]>([]);
    const [selectedFileItem, setSelectedFileItem] = useState<FileDetailsObject | null>(null);
    const [highlightedItem, setHighlightedItem] = useState<FileDetailsObject | null>(null);
    const [cutOrCopiedFiles, setCutOrCopiedFiles] = useState<string[]>([]);
    const [contextMenuItems, setContextMenuItems] = useState([
        { text: 'Cut', iconCss: 'fa fa-scissors' },
        { text: 'Copy', iconCss: 'fa fa-files-o' },
        { text: 'Paste', iconCss: 'fa fa-clipboard' },
        { text: 'Delete', iconCss: 'fa fa-trash-o' },
        { text: 'Download', iconCss: 'fa fa-download' },
        { text: 'Rename', iconCss: 'fa fa-edit' },
        { text: 'Details', iconCss: 'fa fa-info' },
    ]);

    useEffect(() => {
        if (selectedFileItem?.isRoot) {
            // Show only Paste and Details for root items
            setContextMenuItems([
                { text: 'Paste', iconCss: 'fa fa-clipboard' },
                { text: 'Details', iconCss: 'fa fa-info' },
            ]);
        } else {
            // Restore full context menu for non-root items
            setContextMenuItems([
                { text: 'Cut', iconCss: 'fa fa-scissors' },
                { text: 'Copy', iconCss: 'fa fa-files-o' },
                { text: 'Paste', iconCss: 'fa fa-clipboard' },
                { text: 'Delete', iconCss: 'fa fa-trash-o' },
                { text: 'Download', iconCss: 'fa fa-download' },
                { text: 'Rename', iconCss: 'fa fa-edit' },
                { text: 'Details', iconCss: 'fa fa-info' },
            ]);
        }
    }, [selectedFileItem]);

    useEffect(() => {
        if (highlightedItem) {
            // Determine the node to target (file -> parent, folder -> itself)
            const targetNodeId = highlightedItem.isFile ? highlightedItem.parentId : highlightedItem.id;

            const trySelectNode = (nodeId: string | null) => {
                if (!nodeId) return;

                // Ensure the TreeView instance is valid
                const treeViewInstance = treeViewRef?.current;

                if (treeViewInstance) {
                    // Update the selected nodes in the TreeView
                    treeViewInstance.selectedNodes = [nodeId]; // Select the node
                }
            };

            trySelectNode(targetNodeId);
        }
    }, [highlightedItem]);


    useEffect(() => {
        const fetchTreeData = async () => {
            try {
                // Fetch the tree data
                await FileManagerOperations.fetchTreeViewFolders("0");

                // Check if treeViewFolders are available and contain items
                if (FileManagerOperations?.treeViewFolders && FileManagerOperations?.treeViewFolders?.length > 0) {
                    // Find the first item where isRoot is true
                    const firstRootItem = FileManagerOperations.treeViewFolders.find(item => item.isRoot);
                    if (firstRootItem) {
                        // Set the first root item as the selected item in FileManagerOperations
                        FileManagerOperations.setSelectedTreeViewItem(firstRootItem);

                        // Programmatically select the first root node in the TreeView
                        const treeViewInstance = (document.querySelector('.e-treeview') as any)?.ej2_instances[0];
                        const firstRootNodeElement = document.querySelector(`[data-uid="${firstRootItem.id}"]`);
                        if (treeViewInstance && firstRootNodeElement) {
                            treeViewInstance.selectNode(firstRootNodeElement);
                        }
                    } else {
                        console.warn("No root folder found in treeViewFolders.");
                    }
                }
                else {
                    debugger;
                }
            } catch (error) {
                console.error('Error fetching tree data:', error);
            }
        };

        fetchTreeData();
    }, []);

    useEffect(() => {

        const handleTreeUpdate = async () => {
            setTreeViewData(FileManagerOperations.treeViewFolders);
        };

        const handleSelectedFileChanged = () => {
            setSelectedFileItem(FileManagerOperations.selectedFileItem);
        }

        const handleHighlightedItemChanged = () => {
            setHighlightedItem(FileManagerOperations.highlightedItem);
        }

        const handleRefreshTreeNode = async () => {
            const treeViewInstance = treeViewRef?.current;
            const nodeId = FileManagerOperations?.highlightedItem?.id;
            const updatedNodeData = await FileManagerOperations.getFolderGenerations(nodeId);
            if (treeViewInstance) {
                treeViewInstance.refreshNode(nodeId, updatedNodeData);
                treeViewInstance.refresh();
            }
            //const nodeId = FileManagerOperations?.highlightedItem?.id;
            //if (nodeId) {
            //    const updatedNodeData = await FileManagerOperations.getFolderGenerations(nodeId);
            //    const treeViewInstance = treeViewRef?.current;

            //    if (treeViewInstance) {
            //        treeViewInstance.refreshNode(nodeId, updatedNodeData);
            //    }
            //}
        }

        FileManagerOperations.on('treeViewUpdated', handleTreeUpdate);
        FileManagerOperations.on('fileItemSelected', handleSelectedFileChanged);
        FileManagerOperations.on('highlightedItemChanged', handleHighlightedItemChanged);
        FileManagerOperations.on('treeviewNodesUpdated', handleRefreshTreeNode);

        return () => {
            FileManagerOperations.off('treeViewUpdated', handleTreeUpdate);
            FileManagerOperations.off('fileItemSelected', handleSelectedFileChanged);
            FileManagerOperations.off('highlightedItemChanged', handleHighlightedItemChanged);
            FileManagerOperations.off('treeviewNodesUpdated', handleRefreshTreeNode);
        };
    }, [FileManagerOperations]);

    useEffect(() => {

        // Listen for clipboard to GridView
        const handleClipboardUpdates = () => {
            const ids = FileManagerOperations.clipboard.map(item => item.id);
            setCutOrCopiedFiles(ids);
        };

        const handleClipboardClear = () => {
            setCutOrCopiedFiles([]);
        };

        FileManagerOperations.on('clipboardUpdated', handleClipboardUpdates);
        FileManagerOperations.on('clipboardCleared', handleClipboardClear);

        return () => {
            FileManagerOperations.off('clipboardUpdated', handleClipboardUpdates);
            FileManagerOperations.off('clipboardCleared', handleClipboardClear);
        };
    }, [FileManagerOperations]);

    useEffect(() => {
        if (cutOrCopiedFiles?.length > 0) {
            let allNodes = FileManagerOperations.treeViewFolders.map(item => item.id);
            treeViewRef?.current?.enableNodes(allNodes);
            treeViewRef?.current?.disableNodes(cutOrCopiedFiles);
        }
        else {
            let allNodes = FileManagerOperations.treeViewFolders.map(item => item.id);
            treeViewRef?.current?.enableNodes(allNodes);
        }
    }, [cutOrCopiedFiles]);

    useEffect(() => {
        if (FileManagerOperations.selectedGridViewItems?.length === 1) {
            let expectedNode = FileManagerOperations?.highlightedItem;
            if (!FileManagerOperations.highlightedItem?.isFile) {

                if (expectedNode) {
                    FileManagerOperations.expandPath(expectedNode.id, FileManagerOperations.treeViewFolders);
                }
            }
            else {
                if (expectedNode) {
                    FileManagerOperations.expandPath(expectedNode.parentId, FileManagerOperations.treeViewFolders);
                }
            }
        }

    }, [highlightedItem]);

    const handleNodeSelected = async (args: any) => {
        const selectedNodeId = args.nodeData.id;
        const selectedNode = await FileManagerOperations.findTreeNodeById(args.nodeData.id);
        if (selectedNode) {
            FileManagerOperations.setSelectedTreeViewItem(selectedNode);
        } else {
            console.warn('Node not found in tree data:', selectedNodeId);
        }
    };


    const handleNodeExpanded = async (args: NodeExpandEventArgs) => {
        const expandedNode = args.nodeData;

        if (expandedNode && !expandedNode.data) {
            try {
                // Fetch child nodes based on the expanded node's ID
                let id = expandedNode?.id?.toString();
                if (id) {
                    FileManagerOperations.fetchTreeViewFolders(id);
                }

            } catch (error) {
                console.error('Error fetching child nodes:', error);
            }
        }
    };


    function preprocessTreeData(treeData: FileDetailsObject[]): FileDetailsObject[] {
        return treeData.map(item => {
            // Remove the 'data' property since it's not needed
            const { data, ...rest } = item;

            if (item.isRoot || item.parentId === null) {
                // Root node: Remove 'parentId' property
                const { parentId, ...rootNode } = rest;
                return rootNode;
            }

            // Child node: Keep 'parentId' property
            return rest;
        });
    }

    // Preprocessed tree data
    const processedTreeData = preprocessTreeData(treeViewData);

    // Fields configuration for the TreeViewComponent
    const treeViewFields = {
        dataSource: processedTreeData,
        id: 'id', // Unique identifier for each node
        text: 'name', // Text to display
        parentID: 'parentId', // For child nodes (ignored for roots)
        hasChildren: 'hasChild',
        iconCss: '_fm_iconClass',
        expanded: 'isExpanded'
    };


    const handleContextMenuClick = async (args: any) => {
        const targetNode =
            FileManagerOperations.contextMenuTreeViewItem || FileManagerOperations.selectedTreeViewItem;

        if (!targetNode) {
            console.warn('No target node for context menu action.');
            return;
        }

        switch (args.item.text) {
            case 'Open':
                console.log('Opening folder:', targetNode.name);
                break;
            case 'Cut':
                FileManagerOperations.cutItems();
                break;
            case 'Copy':
                FileManagerOperations.copyItems();
                break;
            case 'Paste':
                await FileManagerOperations.pasteItems();
                break;
            case 'Delete':
                await FileManagerOperations.deleteSelectedItems();
                break;
            case 'Download':
                await FileManagerOperations.downloadSelectedItems();
                break;
            case 'Rename':
                FileManagerOperations.setSelectDialog('rename');
                FileManagerOperations.setDialogOpen();
                break;
            case 'Details':
                FileManagerOperations.setSelectDialog('details');
                FileManagerOperations.setDialogOpen();
                break;
            default:
                console.warn('Unknown context menu action:', args.item.text);
        }
    };

    return (
        <div style={{ height: '90vh', overflowY: 'auto' }}>
            <TreeViewComponent
                fields={treeViewFields}
                nodeSelected={handleNodeSelected}
                nodeExpanded={handleNodeExpanded}
                fullRowSelect={true}
                ref={treeViewRef}
            />
            <ContextMenuComponent
                target=".e-treeview"
                items={contextMenuItems}
                select={handleContextMenuClick}
            />
        </div>
    )
}


