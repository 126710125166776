import { BreadcrumbClickEventArgs, BreadcrumbComponent, BreadcrumbItemDirective, BreadcrumbItemsDirective } from '@syncfusion/ej2-react-navigations';
import React, { useEffect, useRef, useState } from 'react'
import { FileDetailsObject } from '../../../models/FileDetails';
import FileManagerOperations from '../Services/FileManagerOperations';
import { FileSession } from '../../../models/FileSession';
interface Props {
    FileManagerOperations: FileManagerOperations
    FileSession: FileSession;
}
export default function FileManagerBreadcrumb({ FileManagerOperations }: Props) {
    const [breadcrumbItems, setBreadcrumbItems] = useState<FileDetailsObject[]>([]);
    const breadcrumbRef = useRef<BreadcrumbComponent>(null); // Ref for the BreadcrumbComponent

    // Update breadcrumb state when highlightedItem changes
    useEffect(() => {
        const handleBreadcrumbUpdates = async () => {
            const breadcrumbs = FileManagerOperations.breadcrumbs || [];
            setBreadcrumbItems([...breadcrumbs]);

            // Debug breadcrumb output
            const breadCrumbing = breadcrumbs.map(item => item.name).join(' <> ');
            console.log('Breadcrumbs:', breadCrumbing);

            // Optionally reset the active item to the last breadcrumb
            if (breadcrumbRef.current) {
                breadcrumbRef.current.activeItem =
                    breadcrumbs[breadcrumbs.length - 1]?.name || '';
            }
        };

        FileManagerOperations.on('highlightedItemChanged', handleBreadcrumbUpdates);

        return () => {
            FileManagerOperations.off('highlightedItemChanged', handleBreadcrumbUpdates);
        };
    }, [FileManagerOperations]);

    // Handle breadcrumb item click
    const handleItemClick = async (args: BreadcrumbClickEventArgs) => {
        const clickedItemId = args.item.id; // Get the clicked item's ID
        const clickedItem = breadcrumbItems.find(item => item.id === clickedItemId);

        if (clickedItem) {
            // Set highlighted item and refresh breadcrumbs
            FileManagerOperations.setHighlightedItem(clickedItem);

            // Reset breadcrumb state to reflect the clicked item
            await FileManagerOperations.updateBreadcrumbs().then(() => {
                const breadcrumbs = FileManagerOperations.breadcrumbs || [];
                setBreadcrumbItems([...breadcrumbs]);

                // Reset active item using ref
                if (breadcrumbRef.current) {
                    breadcrumbRef.current.activeItem =
                        breadcrumbs[breadcrumbs.length - 1]?.name || '';
                }
            });
        }
    };

    // Reset state programmatically using the BreadcrumbComponent ref
    const resetState = () => {
        if (breadcrumbRef.current) {
            const lastItem = breadcrumbItems[breadcrumbItems.length - 1];
            if (lastItem) {
                FileManagerOperations.setHighlightedItem(lastItem);

                breadcrumbRef.current.activeItem = lastItem.name; // Reset active item to the last
                console.log('Breadcrumb reset to:', lastItem);
            }
        }
    };

    return (
        <BreadcrumbComponent
            enableNavigation={true}
            overflowMode='Menu'
            maxItems={4}
            type="Path"
            style={{ width: '200px'} }
            itemClick={handleItemClick}
            ref={breadcrumbRef} // Attach the ref
        >
            <BreadcrumbItemsDirective>
                {breadcrumbItems.map(item => (
                    <BreadcrumbItemDirective
                        key={item.id}
                        text={item.name}
                        iconCss={item?.isRoot ? "fa fa-folder-open" : ''}
                        id={item.id}
                    />
                ))}
            </BreadcrumbItemsDirective>
        </BreadcrumbComponent>
    );
}