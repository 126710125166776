import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { FileDetailsObject } from '../../../models/FileDetails';
import { FileSession } from '../../../models/FileSession';
import { getApiHeaders } from '../../../apis/apiHeaders';
import { url } from 'inspector';
import { fileAddAttachmentService, fileAttachedConnectionsService, fileCopyService, fileCreateFolderService, fileDataGridService, fileDeleteService, fileDownloadService, fileFoldersService, fileItemService, fileMoveService, filePropertiesService, fileRemoveSelectedAttachedService, fileRenameService, refreshFileService } from '../../../apis/fileService';
import { config } from '../../../environment/env_dev';
import { FolderItemsRequest } from '../../../models/FolderItemsRequest';
import { ConnectedSourceItemsRequest } from '../../../models/connectedSourceItemsRequest';
import { AttachedItem } from '../../../models/AttachedItem';
import { RemoveAttachedConnectionsRequest } from '../../../models/RemoveAttachedConnectionsRequest';
import { FileAttachmentRequest } from '../../../models/FileAttachmentRequest';

class FileManagerAPIService {
    private baseUrl: string;
    private activeRequests: Map<string, CancelTokenSource> = new Map();
    private retryLimit = 3; // Retry limit for failed requests

    constructor(baseUrl: string, FileSession: FileSession) {
        this.baseUrl = baseUrl;
    }


    // Helper for making requests with retries, cancellations, and deduplication
    async makeRequest<T>(url: string, config: AxiosRequestConfig, retryCount = 0): Promise<T> {
        const requestKey = `${config.method || 'GET'}:${url}`;
        const source = axios.CancelToken.source();

        if (this.activeRequests.has(requestKey)) {
            throw new Error(`Duplicate request detected: ${requestKey}`);
        }

        this.activeRequests.set(requestKey, source);

        try {
            const response = await axios({ ...config, cancelToken: source.token });
            return response.data;
        } catch (error) {
            if (axios.isCancel(error)) {
                console.warn(`Request canceled: ${requestKey}`);
                throw error;
            }

            // Check if error is an AxiosError and has a response property
            if (axios.isAxiosError(error) && error.response && error.response.status >= 500 && retryCount < this.retryLimit) {
                console.warn(`Retrying request ${requestKey} (${retryCount + 1}/${this.retryLimit})`);
                return this.makeRequest<T>(url, config, retryCount + 1);
            }

            console.error(`Request failed: ${requestKey}`, error);
            throw error;
        } finally {
            this.activeRequests.delete(requestKey);
        }
    }

    // Fetch folders for TreeView with Axios
    async fetchTreeViewFolders(fileId :string,fileSession: FileSession): Promise<FileDetailsObject[]> {
        const folderItemsRequest = new FolderItemsRequest(fileId);
        const response = await fileFoldersService(folderItemsRequest, fileSession);
        const rawData = response.data;
        const childFolders = typeof rawData === "string" ? JSON.parse(rawData) : rawData;
        return childFolders;
    }

    async fetchUpdatedDetails(fileId: string, fileSession: FileSession): Promise<FileDetailsObject> {

        const response = await fileItemService(fileId,fileSession);
        const rawData = response.data;
        const childFolders = typeof rawData === "string" ? JSON.parse(rawData) : rawData;
        return childFolders;
    }

    // Fetch items in a folder for GridView
    async fetchGridViewItems(folderId: string, fileSession: FileSession): Promise<FileDetailsObject[]> {

        const result = await fileDataGridService(new FolderItemsRequest(folderId), fileSession);
        const rawData = result?.data;
        const combinedData = typeof rawData === "string" ? JSON.parse(rawData) : rawData;

        // Ensure that combinedData is of the correct type
        const validData = combinedData as FileDetailsObject[];
        return validData;
    }

    async refreshFiles(providerId: string, fileSession: FileSession): Promise<FileDetailsObject[]> {
        const response = await refreshFileService(fileSession, providerId);
        return response.data;
    }

    // Create a new folder
    async createFolder(parentId: string, folderName: string, fileSession: FileSession): Promise<FileDetailsObject> {

        let response = await fileCreateFolderService(parentId, folderName, "0", fileSession);

        return response.data;
    }

    // Rename an item
    async rename(item: FileDetailsObject, fileSession: FileSession): Promise<FileDetailsObject> {

        const response = await fileRenameService(item.id, item.newName, fileSession); 
        return response.data;
    }

    // Delete an item
    async delete(itemId: string[], fileSession: FileSession): Promise<void> {
        let response = await fileDeleteService(itemId, fileSession);
        return response.data;
    }

    // Copy or move items
    async paste(items: FileDetailsObject[], targetFolderId: string, action: 'copy' | 'cut', fileSession: FileSession): Promise<FileDetailsObject[]> {
        const ids = items.map(item => item.id);
        if (action === 'copy') {
            let response = await fileCopyService(ids, targetFolderId, fileSession);
            return response.data;
        }
        else {
            let response = await fileMoveService(ids, targetFolderId, fileSession);
            return response.data;
        }

    }

    // Upload files
    async uploadFiles(targetFolderId: string, files: File[], fileSession: FileSession): Promise<FileDetailsObject[]> {
        const formData = new FormData();
        files.forEach(file => formData.append('files', file));

        const url = `${this.baseUrl}/folders/${targetFolderId}/upload`;
        const headers = { ...getApiHeaders(fileSession), 'Content-Type': 'multipart/form-data' };
        const config: AxiosRequestConfig = { method: 'POST', url, headers, data: formData };

        return this.makeRequest<FileDetailsObject[]>(url, config);
    }

    async getAttachedItems(fileAttachmentRequests: ConnectedSourceItemsRequest, fileSession: FileSession): Promise<any[]>  {
        const result = await fileAttachedConnectionsService(fileAttachmentRequests, fileSession);
        return result.data.Data as any[];
    }

    async addFilesToAttachedItems(fileAttachmentRequests: FileAttachmentRequest[], fileSession: FileSession): Promise<any> {

        const result = await fileAddAttachmentService(fileAttachmentRequests, fileSession);
        return result.data;
    }

    async removeAttachedItems(removeAttachedConnectionsRequest: RemoveAttachedConnectionsRequest[], fileSession: FileSession): Promise<any> {
        const result = await fileRemoveSelectedAttachedService(removeAttachedConnectionsRequest, fileSession);
        return result.data;
    }

    //fileRemoveSelectedAttachedService

    // Download files
    async downloadFiles(items: FileDetailsObject[], fileSession: FileSession): Promise<void> {

        try {
            const response = await fileDownloadService(items, fileSession);

            const contentDisposition = response.headers['content-disposition'];
            let filename = 'downloaded_file';
            if (contentDisposition) {
                const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (filenameMatch?.[1]) {
                    filename = decodeURIComponent(filenameMatch[1]);
                }
            }

            // Create a Blob from the response data
            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            // Create a download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();

            // Cleanup
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            console.log('Downloaded files successfully');
        } catch (error) {
            console.error('Error downloading files:', error);
        }
    }


}

// Export a singleton instance with a base URL
export default new FileManagerAPIService('https://your-api-base-url.com', new FileSession);
